var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "3 3 18 18" } },
    [
      _c("title", [_vm._v("Menu")]),
      _c("g", { attrs: { "data-name": "Capa 2" } }, [
        _c("g", { attrs: { "data-name": "Capa 1" } }, [
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.02",
              y: "6.02",
              width: "17.96",
              height: "1.97",
              rx: "0.98",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.02",
              y: "11.02",
              width: "17.96",
              height: "1.97",
              rx: "0.98",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.02",
              y: "16.02",
              width: "17.96",
              height: "1.97",
              rx: "0.98",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }